import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"title":_vm.tooltip}},[(_vm.links.length === 1)?_c('router-link',{attrs:{"to":_vm.links[0].to}},[_vm._v(_vm._s(_vm.productName))]):(_vm.links.length > 1)?_c(VMenu,{attrs:{"offset-y":"","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('a',_vm._g({},on),[_vm._v(_vm._s(_vm.productName)+" "),_c('i',{staticClass:"fa fa-caret-down"})])]}}])},[_c(VList,_vm._l((_vm.links),function(link){return _c(VListItem,{key:link.text,attrs:{"to":link.to}},[_c(VListItemTitle,[_vm._v(_vm._s(link.text))])],1)}),1)],1):_c('div',[_vm._v(_vm._s(_vm.productName))])],1)}
var staticRenderFns = []

export { render, staticRenderFns }