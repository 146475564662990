




































































import ListingsTable from '@/areas/listings/components/ListingsTable.vue'
import EditionSelect from '@/areas/editions/components/EditionSelect.vue'
import Vue from 'vue'
import { orderBy } from 'lodash-es'
import Axios from 'axios'
import IListingFlat from '@/areas/listings/models/IListingFlat'
import IListingRelease from '@/areas/listings/models/IListingRelease'
import ISourceDataItem from '../models/ISourceDataItem'
import IMapReleaseRequest from '../models/IMapReleaseRequest'

export default Vue.extend({
  components: {
    ListingsTable,
    EditionSelect
  },
  props: {
    value: Object as () => ISourceDataItem,
    loading: Boolean
  },
  data() {
    return {
      selected: [] as IListingFlat[],
      listingReleases: [] as IListingRelease[],
      releaseId: 0,
      newRelease: false,
      releaseName: null,
      editionId: 0
    }
  },
  watch: {
    releases(releases) {
      if (releases.length === 1) {
        this.releaseId = releases[0].id
      }
    },
    value(newValue: ISourceDataItem) {
      if (!newValue) {
        this.selected = []
      } else {
        const editionCsv = newValue.edition ?? ''
        const editionArray = editionCsv.split(',').map((x) => x.trim())
        const edition = this.$store.getters['Editions/getEditionByName'](
          editionArray[0]
        )

        if (edition) {
          this.editionId = edition.id
        }
      }
    },
    selected: 'getReleases'
  },
  computed: {
    isValid() : boolean {
      let validRelease = false
      if (this.newRelease) {
        validRelease = this.releaseName !== null && this.releaseName.length > 0
      } else {
        validRelease = this.releaseId > 0
      }
      return validRelease && this.selected.length > 0
    },
    title(): string {
      return `Map Source Data: ${this.value?.displayName}`
    },
    releases(): any[] {
      if (!this.selected.length) {
        return []
      }
      const releases = this.listingReleases.map((release) => {
        return {
          id: release.id,
          name: release.release.displayName
        }
      })
      return orderBy(releases, 'name')
    },
    placeholder(): string {
      if (!this.selected.length) {
        return 'Please select a listing.'
      }
      return 'Select Release'
    }
  },
  methods: {
    onCancel() {
      this.$emit('cancel')
    },
    onSubmit() {
      if (this.selected.length) {
        const data: IMapReleaseRequest = {
          newRelease: this.newRelease,
          dataSourceId: this.value.id,
          listingId: this.selected[0].id,
          releaseId: this.releaseId,
          releaseName: this.releaseName,
          concurrency: this.selected[0].concurrency,
          editionId: this.editionId
        }
        this.$emit('submit', data)
      } else {
      }
    },
    async getReleases() {
      this.releaseId = 0
      if (!this.selected[0]) {
        return
      }
      const response = await Axios.get(
        `listings(${this.selected[0].id})?$select=listingReleases&$expand=listingReleases($expand=release)`
      )
      this.listingReleases = response.data.listingReleases
    }
  }
})
