











import Vue from 'vue'
import ConfirmDialog from '@/components/ConfirmDialog.vue'
import ISourceDataItem from '../models/ISourceDataItem'

export default Vue.extend({
  props: {
    show: Boolean,
    value: Object as () => ISourceDataItem
  },
  components: {
    ConfirmDialog
  },
  data() {
    return {
      showDialog: false
    }
  },
  computed: {
    description(): string {
      return `Are you sure you want to activate '${this.value?.displayName}'?`
    }
  },
  methods: {
    async activateConfirmed() {
      this.$emit('activated', this.value)
    }
  },
  watch: {
    show(val) {
      this.showDialog = val
    }
  }
})
