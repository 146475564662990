






























import Vue from 'vue'
import IFlatSourceData from '../models/IFlatSourceData'

export default Vue.extend({
  props: {
    item: Object as () => IFlatSourceData
  },
  computed: {
    mappings(): any[] {
      return this.item.listings.map((listing) => {
        return {
          to: {
            name: 'Listing',
            params: { id: listing.id }
          },
          name: `${listing.name} - ${listing.releaseName}`,
          listingReleaseId: listing.releaseId,
          releaseDataSourceId: listing.releaseDataSourceId
        }
      })
    },
    properties(): any[] {
      const properties = [
        {
          name: 'Source Id',
          field: 'dataSourceUniqueId'
        },
        {
          name: 'Type',
          field: 'productTypeName'
        },
        {
          name: 'Edition(s)',
          field: 'edition'
        },
        {
          name: 'Retirement Announcement',
          field: 'supportEndDate3',
          format: 'date',
          policy: 'Modern - Online Service',
          sources: ['D365']
        },
        {
          name: 'Created',
          field: 'createdDate',
          format: 'date'
        },
        {
          name: 'Modified',
          field: 'modifiedDate',
          format: 'date'
        },
        {
          name: 'Last Imported',
          field: 'lastImportDate',
          format: 'date'
        },
        {
          name: 'Previous SP End',
          field: 'supportEndDate3',
          format: 'date',
          policy: 'Fixed'
        },
        {
          name: 'PMM Lead',
          field: 'pmmlead',
          policy: 'Modern - Online Service',
          sources: ['D365']
        },
        {
          name: 'Product Owner',
          field: 'productOwner',
          policy: 'Modern - Online Service',
          sources: ['D365']
        }
      ]

      return properties
    }
  },
  methods: {
    unmapRelease(mapping) {
      this.$emit('unmap-release', {
        dataSourceId: this.item.id,
        releaseId: mapping.listingReleaseId
      })
    },
    showProperty(property) {
      return (
        (!property.policy || property.policy === this.item.supportPolicyName) &&
        (!property.sources ||
          property.sources.includes(this.item.dataSourceName))
      )
    }
  }
})
