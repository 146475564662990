
















import Vue, { PropType } from 'vue'
import SourceDataHistoryTable from '../components/SourceDataHistoryTable.vue'
import ISourceDataItem from '../models/ISourceDataItem'

export default Vue.extend({
  components: {
    SourceDataHistoryTable
  },
  props: {
    value: Object as PropType<ISourceDataItem>
  },
  computed: {
    id(): number {
      return this.value?.id
    },
    title(): string {
      return `History - ${this.value?.displayName}`
    }
  }
})
