













































































































































import SourceDataMixin from '../mixins/SourceDataMixin'
import ODataTable from '@/components/ODataTable.vue'
import Filters from '@/components/Filters.vue'
import CreateListingDialog from '@/areas/listings/components/CreateListingDialog.vue'
import SourceDataDetails from './SourceDataDetails.vue'
import ProductNameLink from './ProductNameLink.vue'
import MapSourceDataDialog from './MapSourceDataDialog.vue'
import IgnoreSourceDataDialog from './IgnoreSourceDataDialog.vue'
import ActivateSourceDataDialog from './ActivateSourceDataDialog.vue'
import SourceDataHistoryDialog from './SourceDataHistoryDialog.vue'
import IODataTableHeader from '@/models/IODataTableHeader'
import IFlatSourceData from '../models/IFlatSourceData'

export default SourceDataMixin.extend({
  props: {
    dense: Boolean,
    filter: String,
    url: {
      type: String,
      default: 'SourceData/Flat'
    },
    syncUrl: Boolean
  },
  components: {
    ODataTable,
    ProductNameLink,
    CreateListingDialog,
    MapSourceDataDialog,
    IgnoreSourceDataDialog,
    ActivateSourceDataDialog,
    SourceDataHistoryDialog,
    SourceDataDetails,
    Filters
  },
  data() {
    return {
      expanded: [],
      createNewListingRecord: null,
      ignoringRecord: null as IFlatSourceData,
      mappingRecord: null as IFlatSourceData,
      activatingRecord: null as IFlatSourceData,
      sourceDataHistoryRecord: null as IFlatSourceData,
      loading: false
    }
  },
  computed: {
    headers(): IODataTableHeader[] {
      const headers: IODataTableHeader[] = [
        {
          text: 'Name',
          value: 'displayName',
          searchable: true
        },
        {
          text: 'Source',
          value: 'dataSourceName',
          searchable: true
        },
        {
          text: 'Policy',
          value: 'supportPolicyName',
          searchable: true
        },
        {
          text: 'Start date',
          value: 'supportStartDate',
          format: 'date'
        },
        {
          text: 'Mainstream / Retirement',
          value: 'supportEndDate1',
          format: 'date'
        },
        {
          text: 'Extended End',
          value: 'supportEndDate2',
          format: 'date'
        },
        {
          text: 'Last Import Date',
          value: 'lastImportDate',
          format: 'date'
        }
      ]

      if (['Ignored', 'All'].includes(this.$route.query.filter as string)) {
        headers.push({ text: 'Comments', value: 'comments' })
      }

      headers.push({ text: '', value: 'actions', sortable: false, width: 1 })

      return headers
    }
  },
  methods: {
    onCreateListing(model: IFlatSourceData) {
      this.mappingRecord = null
      const editionCsv = model.edition ?? ''
      const editionArray = editionCsv.split(',').map((x) => x.trim())
      const edition = this.$store.getters['Editions/getEditionByName'](
        editionArray[0]
      )

      this.createNewListingRecord = {
        displayName: model.productName,
        supportPolicyId: model.supportPolicyId,
        dataSourceId: model.id,
        editionId: (edition && edition.id) || 0
      }
    },
    onCreateListingConfirmed(listing) {
      this.createNewListingRecord = null
      this.$router.push({
        name: 'Listing',
        params: { id: listing.id.toString() }
      })
    },
    onMapToListing(model) {
      this.mappingRecord = model
    },
    async onMapToListingConfirmed(model) {
      try {
        this.loading = true

        await this.mapToRelease(model)
        this.mappingRecord = null

        this.$router.push({
          name: 'Listing',
          params: { id: model.listingId }
        })
      } finally {
        this.loading = false
      }
    },
    onIgnore(model) {
      this.ignoringRecord = model
    },
    async onIgnoreConfirmed(comment) {
      this.ignoringRecord.comments = comment
      await this.toggleIgnoreMapping(this.ignoringRecord)
    },
    moveToActive(model) {
      this.activatingRecord = model
    },
    async activationConfirmed() {
      await this.toggleIgnoreMapping(this.activatingRecord)

      // close dialog
      this.activatingRecord = null
    },
    onRowClick(item, tableEvent) {
      if (!this.dense) {
        tableEvent.expand(!tableEvent.isExpanded)
      }
    },
    async onUnmapRelease({ dataSourceId, releaseId }) {
      await this.unmapRelease(dataSourceId, releaseId)
    },
    getErrorTooltip(item) {
      return 'Error(s)\n' + item.errors.map((x) => `- ${x}`).join('\n')
    },
    getTitle(item) {
      if (!item || !item.supportPolicyName) {
        return undefined
      }
      if (item.supportPolicyName.startsWith('Modern')) {
        return 'Retirement Date'
      }
      return 'Mainstream Date'
    }
  }
})
